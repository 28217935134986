import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppVouchers = Loadable(lazy(() => import('./vouchers/AppVouchers')));
const AppMovements = Loadable(lazy(() => import('./movements/AppMovements')));

const reportsRoutes = [
    {
        path: '/reports/vouchers',
        element: <AppVouchers />,
    },
    {
        path: '/reports/movements',
        element: <AppMovements />,
    }
];

export default reportsRoutes;