// Import Library
import { styled, Box } from '@mui/material';
import Lottie from "lottie-react";
import Typewriter from "typewriter-effect";

// import colors
import Colors from '../../theme/colors';

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const AnimationRoot = styled(FlexBox)(() => ({
    backgroundImage: `linear-gradient(2deg, ${Colors.primaryColor}, white)`,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
}));

const JustifyBox = styled(FlexBox)(() => ({
    maxWidth: 520,
    flexDirection: 'column',
    justifyContent: 'center',
}));

const BlinkingText = styled('div')(() => ({
    animation: 'blink-animation 1.5s infinite',
    '@keyframes blink-animation': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0 },
        '100%': { opacity: 1 },
    },
}));

const Animation = (props) => {
    const { animation, strings, legend } = props
    return (
        <AnimationRoot>
            <JustifyBox>
                <div style={{ color: Colors.primaryColor, fontSize: '50px', fontWeight: 'bold' }}>
                    <Typewriter
                        options={{
                            strings: strings,
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 50,
                        }}
                    />
                </div>
                <Lottie animationData={animation} loop={true} />
                {legend && (
                    <BlinkingText style={{ color: Colors.background, fontSize: '27px', fontWeight: 'bold' }}>{legend}</BlinkingText >
                )}
            </JustifyBox>
        </AnimationRoot>
    )
}

export default Animation;