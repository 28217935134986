import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const AppBiography = Loadable(lazy(() => import('./AppBiography')));

const biographyRoutes = [
    {
        path: '/biography/default',
        element: <AppBiography />,
        auth: authRoles.admin
    },
];

export default biographyRoutes;