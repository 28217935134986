export const navigations = [

    { name: 'Biografía', path: '/biography/default', icon: 'dashboard' },
    {
        name: 'Administración',
        icon: 'security',
        children: [
            { name: 'Perfil', path: '/administration/profile', iconText: 'F' },
        ],
    },
    {
        name: 'Transacción',
        icon: 'money',
        children: [
            { name: 'Comprar Créditos', path: '/transaction/credits', iconText: 'A' },
            { name: 'Publicar Anuncios', path: '/transaction/advertise', iconText: 'A' },
        ],
    },
    {
        name: 'Reportes',
        icon: 'print',
        children: [
            { name: 'Movimientos', path: '/reports/movements', iconText: 'A' },
        ],
    }
];