import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AppProfile = Loadable(lazy(() => import('./profile/AppProfile')));

const administrationRoutes = [
    {
        path: '/administration/profile',
        element: <AppProfile />,
    },
];

export default administrationRoutes;